import React from 'react';
import { useTranslation } from 'react-i18next';
import MuxPlayer from '@mux/mux-player-react';

import { GenericIcon } from '@/components/atoms/icons/GenericIcon';
import {
    AlarmIconSVG,
    CloseCircleIconSVG,
    TimeDurationIconSVG,
    VideoLoadingIconSVG,
    WifiOffIconSVG,
} from '@/components/atoms/icons/GenericIcon/GenericIcons';
import settings from '@/config/settings';

import styles from './VideoBox.module.scss';
export interface VideoBoxProps {
    cageName: string;
    videoState: string;
    videoSrc?: string;
    timeRemaining?: string;
    userId?: number;
    videoId?: string;
}

const VideoBox = ({
    cageName = '',
    videoState = '',
    videoSrc = '',
    timeRemaining = '',
    userId = 0,
    videoId = '',
}: VideoBoxProps) => {
    const { t } = useTranslation();
    const renderContent = () => {
        if (videoState === 'offline') {
            return (
                <>
                    <div className={styles.stateContainer}>
                        <GenericIcon
                            icon={WifiOffIconSVG}
                            color={settings.darkGrayColor}
                            size={52}
                        />
                        <div className={styles.message}>{t('The bioscope is offline.')}</div>
                    </div>
                    <div className={styles.videoOverlay}>
                        <div>{cageName}</div>
                    </div>
                </>
            );
        }
        if (
            videoState === 'idle' ||
            videoState === 'connected' ||
            videoState === 'starting' ||
            videoState === 'recording'
        ) {
            return (
                <>
                    <div className={styles.stateContainer}>
                        <GenericIcon icon={VideoLoadingIconSVG} color={'#ffffff'} size={52} />
                        <div className={styles.message}>{t('The video is loading...')}</div>
                    </div>
                    <div className={styles.videoOverlay}>
                        <div>{cageName}</div>
                    </div>{' '}
                </>
            );
        }
        if (videoState === 'active') {
            return (
                <>
                    <MuxPlayer
                        playbackId={videoSrc}
                        accentColor={settings.primaryColor}
                        autoPlay={true}
                        metadata={{
                            video_id: videoId,
                            video_title: cageName,
                            viewer_user_id: userId,
                        }}
                    />
                    <div className={styles.videoOverlay}>
                        <div>{cageName}</div>
                        <div className={styles.timer}>
                            <GenericIcon icon={TimeDurationIconSVG} color={'#ffffff'} size={20} />
                            <span>{timeRemaining}</span>
                        </div>
                    </div>
                </>
            );
        }
        if (videoState === 'error' || videoState === 'disconnected') {
            return (
                <>
                    <div className={styles.stateContainer}>
                        <GenericIcon
                            icon={CloseCircleIconSVG}
                            color={settings.darkGrayColor}
                            size={52}
                        />
                        <div className={styles.message}>{t('Error occured')}</div>
                    </div>
                    <div className={styles.videoOverlay}>
                        <div>{cageName}</div>
                    </div>
                </>
            );
        }
        if (videoState === 'timeout') {
            return (
                <>
                    <div className={styles.stateContainer}>
                        <GenericIcon icon={AlarmIconSVG} color={settings.darkGrayColor} size={52} />
                        <div className={styles.message}>
                            {t('The allocated 1-hour viewing time for today has been reached!')}
                        </div>
                    </div>
                    <div className={styles.videoOverlay}>
                        <div>{cageName}</div>
                        <div className={styles.timer}>
                            <GenericIcon
                                icon={TimeDurationIconSVG}
                                color={settings.warningColor}
                                size={20}
                            />
                            <span className={styles.timeout}>{timeRemaining}</span>
                        </div>
                    </div>
                </>
            );
            // } else {
            //     return (
            //         <>
            //             <div className={styles.stateContainer}>
            //                 <GenericIcon
            //                     icon={CloseCircleIconSVG}
            //                     color={settings.darkGrayColor}
            //                     size={52}
            //                 />
            //                 <div className={styles.message}>{t('Something happened...')}</div>
            //             </div>
            //             <div className={styles.videoOverlay}>
            //                 <div>{cageName}</div>
            //             </div>
            //         </>
            //     );
        }
    };

    return <div className={styles.videoBox}>{renderContent()}</div>;
};

export default VideoBox;
export { VideoBox };
