import {
    CompareIconSVG,
    DashboardIconSVG,
    HelpIconSVG,
    LiceIconSVG,
    MoveBioscopeIconSVG,
    UserIconSVG,
    VideoCameraIconSVG,
    WinchIconSVG,
} from '@/components/atoms/icons/GenericIcon/GenericIcons';
import { GenericPage, SidebarType } from '@/components/molecules/GenericSidebar';
import { SidebarPageOptionObject } from '@/components/molecules/GenericSidebar/SidebarPageOptionObjects';
import UserPage from '@/components/UserManagmentPage';
import LICENSES from '@/config/licenses';
import HelpPageContainer from '@/routes/Client/components/Help/HelpPageContainer';
import LocationLiceReportContainer from '@/routes/Client/Location/pages/LocationLiceReport/LocationLiceReportContainer';
import { MoveBioscopeContainer } from '@/routes/Client/MoveBioScope/MoveBioscopeContainer';

import LocationVideoContainer from './LocationVideo/LocationVideoContainer';
import LocationWinchContainer from './LocationWinch/LocationWinchContainer';
import LocationCompare from './LocationCompare';
import LocationDashboard from './LocationDashboard';

export type Pages = GenericPage[];

const pages: Pages = [
    {
        id: 1,
        exact: true,
        path: '',
        label: 'Dashboard',
        page: LocationDashboard,
        sidebarToShowFor: SidebarType.locationLevel,
        image: DashboardIconSVG,
    },
    {
        id: 4,
        path: '/lice-report',
        label: 'Lice report',
        page: LocationLiceReportContainer,
        sidebarToShowFor: SidebarType.locationLevel,
        exact: true,
        image: LiceIconSVG,
        licenses: [LICENSES.LICE_COUNT.MIN, LICENSES.LICE_COUNT.MAX],
    },
    {
        id: 2,
        path: '/compare',
        label: 'Compare',
        page: LocationCompare,
        sidebarToShowFor: SidebarType.locationLevel,
        exact: true,
        image: CompareIconSVG,
    },
    {
        id: 3,
        path: '/video',
        label: 'Video',
        page: LocationVideoContainer,
        sidebarToShowFor: SidebarType.locationLevel,
        exact: true,
        image: VideoCameraIconSVG,
    },
    {
        id: 4,
        path: '/winch',
        label: 'winch',
        page: LocationWinchContainer,
        sidebarToShowFor: SidebarType.locationLevel,
        exact: true,
        image: WinchIconSVG,
    },
    {
        id: 6,
        path: '/move-bioscope',
        label: 'Move bioscope',
        page: MoveBioscopeContainer,
        sidebarToShowFor: SidebarType.locationLevel,
        exact: true,
        image: MoveBioscopeIconSVG,
        staticOption: true,
    },
    {
        id: 10,
        path: '/users',
        label: 'Users',
        page: UserPage,
        sidebarToShowFor: SidebarType.locationLevel,
        exact: true,
        image: UserIconSVG,
        staticOption: true,
    },
    {
        id: 11,
        path: '/help',
        label: 'Help',
        page: HelpPageContainer,
        sidebarToShowFor: SidebarType.locationLevel,
        exact: true,
        image: HelpIconSVG,
        staticOption: true,
    },

    /*
    {
        id: 4,
        parentId: 2,
        path: '/compare/health',
        label: 'Test1',
        page: LocationCompare,
        sidebarToShowFor: SidebarType.locationLevel,
        exact: true,
    },
    {
        id: 7,
        parentId: 1,
        path: '/compare/health',
        label: 'Test1',
        page: LocationCompare,
        sidebarToShowFor: SidebarType.locationLevel,
        exact: true,
    },
    */
].map((page, index) => ({ key: index, ...page }));

export const locationSidebarOptions: SidebarPageOptionObject[] = pages.map((item) => {
    return {
        page: item,
        url: '',
        active: false,
    };
});

export default pages;
export { pages };
