import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Buffer } from 'buffer';

import Loader from '@/components/atoms/Loader/Loader';
import { UmerBox } from '@/components/atoms/UmerBox';
import { UmerBoxElementHeader } from '@/components/atoms/UmerBoxElements/UmerBoxElementHeader';
import VideoBox from '@/components/molecules/VideoBox';
import SomethingHappened from '@/components/SomethingHappened';
import { useMe } from '@/contexts/meContext';
import { useMqtt } from '@/contexts/mqtt-provider-context';
import LocationVideoPageContent from '@/routes/Client/Location/pages/LocationVideo/LocationVideoPageContent';
import {
    useClientController_Overview,
    useVideoStreamController_startStream,
    useVideoStreamController_stopStream,
} from '@/services/hooks';
import { OverviewCage, OverviewLocation } from '@/services/types';

import { mqttTopicsVideo } from './topics';

import styles from './LocationVideo.module.scss';

const LocationVideoContainer = ({ clientId, locationId }) => {
    const {
        data: clientLocationsData,
        isLoading: isLoadingClientLocations,
        error: clientLocationsDataError,
    } = useClientController_Overview(clientId, {
        staleTime: 5 * 60 * 1000,
        cacheTime: 5 * 60 * 1000,
    });
    const { t, i18n } = useTranslation();
    const { client, isConnected, publish, subscribe, unsubscribe } = useMqtt();
    const { state } = useMe();
    const user_id = state?.user?.id;
    const [currentLocation, setCurrentLocation] = useState<OverviewLocation>(null);
    const [filteredCages, setFilteredCages] = useState<OverviewCage[]>([]);
    const [currentCages, setCurrentCages] = useState<OverviewCage[]>([]);
    const [filteredBioscopes, setFilteredBioscopes] = useState<
        { bioscopeId: number; cageName: string }[]
    >([]);
    const [currentBioscopes, setCurrentBioscopes] = useState<
        {
            bioscopeId: number;
            cageName: string;
            streamId?: string;
            status?: string;
            muxPlayerData?: any;
        }[]
    >([]);
    const { mutateAsync: startStream } = useVideoStreamController_startStream();
    const { mutateAsync: stopStream } = useVideoStreamController_stopStream();

    const mockBioscope1 = {
        bioscopeId: 1323,
        cageName: 'Mock Cage 1',
        streamId: '',
        status: '',
    };

    const mockBioscope2 = {
        bioscopeId: 1396,
        cageName: 'Mock Cage 2',
        streamId: '',
        status: '',
    };

    useEffect(() => {
        const location = clientLocationsData?.data?.locations?.find(
            (x) => x.id === locationId
        ) as OverviewLocation;
        if (location) {
            setCurrentLocation(location);
            setFilteredCages([
                ...location.cages.filter((x) => x.activeBioscopes && x.activeBioscopes.length > 0),
                {
                    id: 1,
                    name: 'Mock Cage 1',
                    activeBioscopes: [mockBioscope1.bioscopeId],
                    historical: 'false',
                    weight: {
                        ackedStatusList: [],
                        day: '',
                        growth: 0,
                        livingWeight: 0,
                        measStatusLevel: 0,
                        measStatusList: [],
                    },
                },
                {
                    id: 2,
                    name: 'Mock Cage 2',
                    activeBioscopes: [mockBioscope2.bioscopeId],
                    historical: 'false',
                    weight: {
                        ackedStatusList: [],
                        day: '',
                        growth: 0,
                        livingWeight: 0,
                        measStatusLevel: 0,
                        measStatusList: [],
                    },
                },
            ]);

            const activeBioscopes = location.cages.flatMap((x) =>
                x.activeBioscopes && x.activeBioscopes.length > 0
                    ? x.activeBioscopes.map((bioscopeId) => ({
                          bioscopeId,
                          cageName: x.name,
                      }))
                    : []
            );
            setFilteredBioscopes([...activeBioscopes, mockBioscope1, mockBioscope2]);
        } else {
            setCurrentLocation(null);
            setFilteredCages([]);
        }
    }, [clientLocationsData, locationId]);

    const handleCagesChanged = (cages: OverviewCage[]) => {
        const newBioscopes = cages.flatMap((cage) =>
            cage.activeBioscopes && cage.activeBioscopes.length > 0
                ? cage.activeBioscopes.map((bioscopeId) => ({
                      bioscopeId,
                      cageName: cage.name,
                  }))
                : []
        );

        // Detect deselected bioscopes
        const deselectedBioscopes = currentBioscopes.filter(
            (bioscope) =>
                !newBioscopes.some((newBioscope) => newBioscope.bioscopeId === bioscope.bioscopeId)
        );

        // Publish stop_stream message for deselected bioscopes
        for (const bioscope of deselectedBioscopes) {
            console.log('Stopping stream:', bioscope);
            const { videoStatus: muxStatusTopic, bioscopeStatus: bioscopeStatusTopic } =
                mqttTopicsVideo(bioscope.bioscopeId);
            unsubscribe(muxStatusTopic, { qos: 1 });
            unsubscribe(bioscopeStatusTopic, { qos: 1 });
            console.log('unsubscribed from:', muxStatusTopic, bioscopeStatusTopic);
            stopStream(
                { bioscopeId: bioscope.bioscopeId },
                {
                    onSuccess: (response) => {
                        console.log('Stream stopped successfully:', response);
                    },
                    onError: (error) => {
                        console.error('Error stopping stream:', error);
                    },
                }
            );
            setCurrentBioscopes(
                currentBioscopes.filter((b) => b.bioscopeId !== bioscope.bioscopeId)
            );
            setCurrentCages(cages);
        }

        // Detect newly selected bioscopes
        const newlySelectedBioscopes = newBioscopes.filter(
            (newBioscope) =>
                !currentBioscopes.some((bioscope) => bioscope.bioscopeId === newBioscope.bioscopeId)
        );

        // Publish start_stream message for newly selected bioscopes
        for (const bioscope of newlySelectedBioscopes) {
            console.log('Starting stream:', bioscope);
            const { videoStatus: muxStatusTopic, bioscopeStatus: bioscopeStatusTopic } =
                mqttTopicsVideo(bioscope.bioscopeId);
            subscribe(muxStatusTopic, { qos: 1 });
            console.log('subscribed to:', muxStatusTopic);
            subscribe(bioscopeStatusTopic, { qos: 1 });
            console.log('subscribed to:', bioscopeStatusTopic);
            startStream(
                { bioscopeId: bioscope.bioscopeId },
                {
                    onSuccess: (response) => {
                        console.log('Stream started successfully:', response);
                        const updatedBioscopes = [
                            ...currentBioscopes,
                            ...newlySelectedBioscopes.map((b) =>
                                b.bioscopeId === bioscope.bioscopeId
                                    ? {
                                          ...b,
                                          status: response?.data.status,
                                          muxPlayerData: response?.data,
                                      }
                                    : b
                            ),
                        ];
                        setCurrentBioscopes(updatedBioscopes);
                        setCurrentCages(cages);
                    },
                    onError: (error) => {
                        console.error('Error starting stream:', error);
                    },
                }
            );
        }
    };
    useEffect(() => {
        if (isConnected) {
            const handleMessage = (topic: string, message: Buffer) => {
                const msg = message.toString();
                for (const bioscope of currentBioscopes) {
                    if (topic === mqttTopicsVideo(bioscope.bioscopeId).videoStatus) {
                        console.log(`message received for topic ${topic}: ${msg}`);
                        const parsedMessage = JSON.parse(msg);
                        const updatedBioscopes = currentBioscopes.map((bioscope) => {
                            return {
                                ...bioscope,
                                streamId: parsedMessage.streamId,
                                status: parsedMessage.status,
                                muxStatus: parsedMessage.muxStatus,
                                timestamp: parsedMessage.timestamp,
                            };
                        });
                        setCurrentBioscopes(updatedBioscopes);
                    } else if (topic === mqttTopicsVideo(bioscope.bioscopeId).bioscopeStatus) {
                        console.log(`status received for topic ${topic}: ${msg}`);
                    }
                }
            };

            client?.on('message', handleMessage);

            return () => {
                client?.off('message', handleMessage);
            };
        }
    }, [client, currentBioscopes, isConnected]);

    return (
        <div className={styles.pageContent}>
            {(isLoadingClientLocations || !currentLocation) && <Loader />}
            {clientLocationsDataError && <SomethingHappened />}
            {currentLocation && (
                <div>
                    <LocationVideoPageContent
                        fullFilteredCagesList={filteredCages}
                        currentCages={currentCages}
                        onCagesChanged={handleCagesChanged}
                        location={currentLocation}
                    />
                </div>
            )}
            <UmerBox>
                <UmerBoxElementHeader title={t('Video')} />
                <section className={styles.videoGrid}>
                    {currentBioscopes.length > 0 ? (
                        currentBioscopes.map((bioscope) =>
                            bioscope.muxPlayerData ? (
                                <VideoBox
                                    key={bioscope.bioscopeId}
                                    cageName={bioscope.cageName}
                                    videoState={bioscope?.status || ''}
                                    userId={user_id}
                                    videoId={`video-id-${bioscope.bioscopeId}`}
                                    videoSrc={bioscope?.muxPlayerData?.playback_id}
                                    timeRemaining={'01.00.00'}
                                />
                            ) : (
                                <VideoBox
                                    key={bioscope.bioscopeId}
                                    cageName={bioscope.cageName}
                                    videoState={bioscope?.status || ''}
                                    userId={user_id}
                                    videoId={`video-id-${bioscope.bioscopeId}`}
                                    videoSrc={''}
                                    timeRemaining={''}
                                />
                            )
                        )
                    ) : (
                        <div>
                            <h2>{t('Live Video Streaming')}</h2>
                            <ul>
                                <li>
                                    {t(
                                        'Use the pen selectors above to choose the pen from where you want to stream video.'
                                    )}
                                </li>
                                <li>
                                    {t('You can toggle between multiple pens or select all pens.')}
                                </li>
                                <li>
                                    {t(
                                        'The allocated time for live streaming is 1-hour per day, as live streaming interrupts fish recording. Thank you for understanding!'
                                    )}
                                </li>
                            </ul>
                        </div>
                    )}
                </section>
            </UmerBox>
        </div>
    );
};

export default LocationVideoContainer;
export { LocationVideoContainer };
